<template>
	<div>
		<a-modal title="添加线下补单" :width="540" :visible="visible"  @cancel="handleCancel" >
			<template slot="footer" >
				<a-button  key="back"  @click="handleCancel">
					取消
				</a-button>
				<a-button  key="submit" type="primary" @click="onSubmit" :loading="confirmLoading">
					确定补单
				</a-button>
			</template>
			
			<div>
				<a-spin :spinning="showMemberLoading">
					<div class="flex alcenter" v-if="JSON.stringify(form.member) == '{}'">
						<div class="ft14 ftw500 cl-info">选择用户</div>
						<div class="alcenter flex ml10">
							<a-input-search v-model="form.mobile" placeholder="请输入用户手机号"  style="width: 160px" @search="onSearch" />
							<div class="ml10 flex alcenter ft12 ftw400 cl-notice" v-if="msgStatus">
								<div>暂无该用户，请重新输入或 </div>
								<div class="cl-theme clickAct" @click="addMemberAct()">添加会员</div>
							</div>
						</div>
					</div>
					
					<div class="flex alcenter" v-if="JSON.stringify(form.member) != '{}'">
						<div class="ft14 ftw500 cl-info">选中的用户</div>
						<div class="flex alcenter ml10">
							<div class="flex alcenter">
								<div class="ft14 ftw400 cl-main">昵称：</div>
								<div class="ft14 ftw400 cl-black ml5">{{form.member.nick_name}}</div>
							</div>
							
							<div class="flex alcenter ml20">
								<div class="ft14 ftw400 cl-main">手机号：</div>
								<div class="ft14 ftw400 cl-black ml5">{{form.member.mobile}}</div>
							</div>
							
							<div class=" ml20">
								<a-button type="primary" @click="reChooseAct()">重新选择</a-button>
							</div>
						</div>
					</div>
				</a-spin>
				
				<div class="flex alcenter mt24">
					<div class="ft14 ftw500 cl-info">消耗金额</div>
					
					<div class="ml16">
						<a-input-number style="width: 160px;" placeholder="请输入" v-model="form.money" :precision="2" :min="0" :formatter="value => `${value}元`"/>
					</div>
					
					<div class="ml8 ft12 ftw400 cl-notice">此金额会立马形成一条充值记录并消耗掉</div>
				</div>
				
				<div v-if="addMemberVisible">
					<add-member v-model="form.member" :visible="addMemberVisible" @cancel="cancelAddMember" @ok="okAddMember"></add-member>
				</div>
			</div>
				
		</a-modal>
	</div>
</template>

<script>
	import addMember from '../../../../mall/components/CustomerToOrder/modal/addMember.vue';
	export default {
		props: {
			visible:{
				type:Boolean,
				default:false,
			},
		},
		components:{
			addMember,
		},
		data() {
			return {
				loading: false,
				confirmLoading: false,
				addMemberVisible:false,
				showMemberLoading:false,
				msgStatus:false,
				form: {
					member:{},
					money:0,
				}
			}
		},
		methods: {
			
			onSearch(){
				if(this.showMemberLoading==true) return;
				this.showMemberLoading=true;
				this.$http.api('admin/getMemberWithMobileFromSupplement',{
					mobile:this.form.mobile,
				}).then(res=>{
					this.msgStatus=false;
					this.form.member=res.member;
					this.showMemberLoading=false;
				}).catch(res=>{
					this.showMemberLoading=false;
					this.msgStatus=true;
				})
				
			},
			
			reChooseAct(){
				this.msgStatus=false;
				this.form.member=new Object;
			},
			
			addMemberAct(){
				this.addMemberVisible=true;
			},
			cancelAddMember(){
				this.addMemberVisible=false;
			},
			okAddMember(){
				this.msgStatus=false;
				this.addMemberVisible=false;
			},
			
			/**
			 * 取消弹窗
			 */
			handleCancel() {
				this.$emit("cancel");
			},
	
			/**
			 * 提交数据
			 */
			onSubmit() {
				if(JSON.stringify(this.form.member) == '{}'){
					this.$message.error('请选择会员');
					return;
				}
				
				if(this.confirmLoading==true) return;
				this.confirmLoading=true;
				this.$http.api('admin/saveSupplement',{
					member_id:this.form.member.member_id,
					money:this.form.money,
				}).then(res=>{
					this.$message.success('补单成功',1,()=>{
						this.confirmLoading=false;
						this.$emit("ok");
					})
					
				}).catch(res=>{
					console.log(res);
					this.confirmLoading=false;
				})
			},
			
		}
	}
</script>

<style>
	
</style>
