<template>
	<div>
		<div class="ft20 cl-black cl-main ftw500">线下补单</div>

		<div class="mt20">
			<div class="form-search-box ">
				<a-form layout="inline">
					<a-form-item label="会员昵称">
						<a-input v-model="search.nick_name" placeholder="请输入会员昵称"></a-input>
					</a-form-item>

					<a-form-item label="会员手机号">
						<a-input v-model="search.mobile" placeholder="请输入会员手机号"></a-input>
					</a-form-item>

					<a-form-item>
						<a-button @click="searchAct" type="primary">查询</a-button>
						<a-button @click="cancelAct" class="ml10">取消</a-button>
					</a-form-item>
				</a-form>
			</div>
		</div>

		<div class="mt20">
			<div class="pd20 bg-w">
				<div>
					<a-button type="primary" icon="plus" @click="addSupplementAct()">添加补单</a-button>
				</div>
				
				<div class="mt20">
					<div class="wxb-table-gray">
						<a-table rowKey="id" :columns="columns" :pagination="pagination" @change="handleTableChange" :data-source="datas"
						 :loading="loading">
							<div class="flex alcenter center" slot="member_id" slot-scope="record">
								{{record.member.member_id}}
							</div>
						 
							<div class="flex alcenter center" slot="member" slot-scope="member,record">
								<img v-if="member.face!=null" :src="member.face" class="member-index-face" />
								<img v-else src="../../assets/image/woyaoyou_head_defult@2x.png" class="member-index-face" />
								<div class="ml5" style="text-align: left;">
									<div>{{member.nick_name}}</div>
								</div>
							</div>
							
							<div class="flex alcenter center" slot="mobile" slot-scope="record">
								{{record.member.mobile}}
							</div>
							
							<div class="flex alcenter center" slot="money" slot-scope="money,record">
								-¥{{money}}
							</div>
							
							</a-table>
					</div>
							
				</div>
			</div>
		</div>
	
		<add-supplement :visible="addSupplementVisible" @cancel="cancelAddSupplement" @ok="okAddSupplement"></add-supplement>
	</div>
</template>

<script>
	import {
		listMixin
	} from '../../common/mixin/list.js';
	import addSupplement from './components/Supplement/modal/addSupplement.vue';
	export default {
		mixins: [listMixin],
		components:{
			addSupplement,
		},
		data() {
			return {
				loading: false,
				addSupplementVisible:false,
				pagination: {
					current: 1,
					pageSize: 10, //每页中显示10条数据
					total: 0,
				},
				search: {
					nick_name: '',
					mobile: "",
				},
				level:[],
				columns: [
					{title: '会员编号',key: 'member_id',align: 'center',scopedSlots: {customRender: 'member_id'}},
					{title: '会员',dataIndex: 'member',align: 'center',scopedSlots: {customRender: 'member'}},
					{title: '手机号',key: 'mobile',align: 'center',scopedSlots: {customRender: 'mobile'}},
					{title: '补单时间',dataIndex: 'add_time_format',align: 'center',ellipsis: true},
					{title: '操作者',dataIndex: 'operate_name',align: 'center',ellipsis: true},
					{title: '消耗金额',dataIndex: 'money',align: 'center',scopedSlots: {customRender: 'money'}},
				],
				datas: [],
			}
		},
		methods: {
			getLists(){
				if(this.loading==true) return;
				this.loading=true;
				this.$http.api('admin/getSupplementList',{
					limit:this.pagination.pageSize,
					page:this.pagination.current,
					nick_name:this.search.nick_name,
					mobile:this.search.mobile,
				}).then(res=>{
					this.pagination.total=res.total;
					this.datas=res.list;
					this.loading=false;
				}).catch(res=>{
					console.log(res);
					this.loading=false;
				})
			},
			
			addSupplementAct(){
				this.addSupplementVisible=true;
			},
			cancelAddSupplement(){
				this.addSupplementVisible=false;
			},
			okAddSupplement(){
				this.addSupplementVisible=false;
				this.getLists();
			},
			
			handleTableChange(pagination, filters, sorter) {
				this.pagination.current = pagination.current;
				this.getLists();
			},
		}
	}
</script>

<style>
	.member-index-face {
		width: 44px;
		height: 44px;
		border-radius: 44px;
		box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.04);
		border: 2px solid #FFFFFF;
	}

	.member-index-action {
		width: 32px;
		height: 32px;
		border-radius: 32px;
		background: #FFFFFF;
		border: 1px solid #EBEDF5;
		text-align: center;
		line-height: 32px;
	}
</style>
